// ** UseJWT import to get config
import UseJwt from "@src/auth/jwt/useJwt";
import { saveUserData } from "@utils";
const config = UseJwt.jwtConfig;

// ** Handle User Login
export const handleLogin = (userData, accessToken, refreshToken) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN",
      data: userData,
      config,
      [config.storageTokenKeyName]: userData[config.storageTokenKeyName],
      [config.storageRefreshTokenKeyName]:
        userData[config.storageRefreshTokenKeyName],
    });

    // ** Add to user, accessToken & refreshToken to localStorage
    saveUserData(userData);
    localStorage.setItem(
      config.storageTokenKeyName,
      JSON.stringify(accessToken)
    );
    localStorage.setItem(
      config.storageRefreshTokenKeyName,
      JSON.stringify(refreshToken)
    );
    localStorage.setItem("welcomed", !userData["onboard"]);
  };
};

// ** Handle User Logout
export const handleLogout = () => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
      [config.storageTokenKeyName]: null,
      [config.storageRefreshTokenKeyName]: null,
    });

    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem("userData");
    localStorage.removeItem("welcomed");
    localStorage.removeItem("setAlreadyFetchingAccessToken");
    localStorage.removeItem(config.storageTokenKeyName);
    localStorage.removeItem(config.storageRefreshTokenKeyName);
  };
};
