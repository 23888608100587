import { HighlightStyle, tags } from "@codemirror/highlight";
import { html } from "@codemirror/lang-html";
import { json } from "@codemirror/lang-json";
import { xml } from "@codemirror/lang-xml";
import { EditorView } from "@codemirror/view";
import { useSkin } from "@hooks/useSkin";
import CodeMirror from "@uiw/react-codemirror";
import { useEffect, useState } from "react";

/**
 * Ddosify Editor is a generic text editor with a few builtin language supports
 *
 * props.lang     => Editor language (Supporting: json|xml|html). If not or invalid given, normal text editor will be created.
 * props.value    => Value of the editor content. If not provided, default values will be inserted into the editor content
 * props.onChange => onChange callback. Called everytime content change.
 */
const DdosifyEditor = (props) => {
  const {
    lang,
    value,
    onChange,
    readonly = false,
    maxHeight = "50rem",
    minWidth,
  } = props;

  const jsonDefaultVal = `{

}`;
  const xmlDefaultVal = "<>";
  const htmlDefaultVal = `<html>

</html>`;

  const darkTheme = EditorView.theme(
    {
      "&": {
        color: "#56b6c2 !important",
        backgroundColor: "#364570 !important",
      },
      ".cm-content": {
        caretColor: "#61afef",
      },
      ".cm-activeLine": {
        backgroundColor: "transparent !important",
      },
      ".cm-gutters": {
        backgroundColor: "#364570 !important",
        borderRight: "1px solid #4d629d !important",
      },
      ".cm-activeLineGutter": {
        backgroundColor: "#5368a5 !important",
      },
      ".cm-cursor": {
        borderLeft: "1.2px solid #d88b44 !important",
      },
      "cm-line > span.cd": {
        color: "orange",
      },
    },
    { dark: false }
  );

  const darkHighlight = HighlightStyle.define([
    { tag: tags.propertyName, color: "#60a5fa" },
    { tag: tags.string, color: "#e879f9" },
    { tag: tags.number, color: "#a78bfa" },
  ]);

  const lightTheme = EditorView.theme(
    {
      "&": {
        color: "#56b6c2 !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #e3e0e0",
      },
      ".cm-content": {
        caretColor: "#61afef",
      },
      ".cm-activeLine": {
        backgroundColor: "transparent !important",
      },
      ".cm-gutters": {
        backgroundColor: "#ffffff !important",
        borderRight: "1px solid #e3e1e1 !important",
      },
      ".cm-activeLineGutter": {
        backgroundColor: "#ffffff !important",
      },
      ".cm-cursor": {
        borderLeft: "1.2px solid #d88b44 !important",
      },
      "cm-line > span.cd": {
        color: "orange",
      },
    },
    { dark: false }
  );

  const lightHighlight = HighlightStyle.define([
    { tag: tags.propertyName, color: "#60a5fa" },
    { tag: tags.string, color: "#e879f9" },
    { tag: tags.number, color: "#a78bfa" },
  ]);

  const [skin, setSkin] = useSkin();
  const [customTheme, setCustomTheme] = useState();
  const [customHighlight, setCustomHighlight] = useState();
  const [editorLang, setEditorLang] = useState();

  useEffect(() => {
    let val = "";
    switch (lang) {
      case "json":
        val = jsonDefaultVal;
        setEditorLang(json());
        break;
      case "html":
        val = htmlDefaultVal;
        setEditorLang(html());
        break;
      case "xml":
        val = xmlDefaultVal;
        setEditorLang(xml());
        break;
      default:
        setEditorLang(undefined);
        break;
    }

    if (
      value != null &&
      [jsonDefaultVal, xmlDefaultVal, htmlDefaultVal].indexOf(value) === -1
    ) {
      val = value;
    }
    onChange(val);
  }, [lang, value]);

  useEffect(() => {
    if (skin === "dark") {
      setCustomTheme(darkTheme);
      setCustomHighlight(darkHighlight);
    } else {
      setCustomTheme(lightTheme);
      setCustomHighlight(lightHighlight);
    }
  }, [skin]);

  return (
    <CodeMirror
      value={value}
      minHeight="200px"
      maxHeight={maxHeight}
      minWidth={minWidth}
      extensions={
        editorLang
          ? [customTheme, customHighlight, editorLang]
          : [customTheme, customHighlight]
      }
      onChange={(value, _) => onChange(value)}
      editable={!readonly}
    />
  );
};

export default DdosifyEditor;
