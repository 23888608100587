import { createSlice } from "@reduxjs/toolkit";

export const authTokensSlice = createSlice({
  name: "authTokens",
  initialState: { accessToken: null, refreshToken: null },
  reducers: {
    setAuthTokens: (state, action) => {
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
    },
    clearAuthTokens: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
});

export const { setAuthTokens, clearAuthTokens } = authTokensSlice.actions;

export default authTokensSlice.reducer;
