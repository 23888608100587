import { createApi } from "@reduxjs/toolkit/query/react";
import sysConfig from "@src/configs/sysConfig";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";

export const dashboardApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Dashboard", "Engines"],
  reducerPath: "dashboardApi",
  keepUnusedDataFor: 1,
  refetchOnFocus: true,
  refetchOnMountOrArgChange: sysConfig.isSelfHosted ? 30 : 20,
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getDashboard: build.query({
      query: () =>
        sysConfig.isSelfHosted ? "/selfhosted/dashboard/" : "/dashboard/",
      providesTags: ["Dashboard"],
    }),
    getDashboardUsage: build.query({
      query: () => "/dashboard/usage/",
      tag: "DashboardUsage",
    }),
    getEngines: build.query({
      query: () =>
        "/selfhosted/engines/",
      providesTags: ["Engines"],
    }),
    removeEngine: build.mutation({
      query: (engineId) => ({
        url: "/selfhosted/engines/" + engineId + "/",
        method: "DELETE",
      }),
      invalidatesTags: ["Dashboard", "Engines"],
    }),
    openDistributedMode: build.mutation({
      query: (key) => ({
        url: "/selfhosted/unlockdist/",
        method: "POST",
        body: { license_key: key },
      }),
      invalidatesTags: ["Dashboard", "Engines"],
    }),
  }),
});

export const {
  useGetDashboardQuery,
  useGetDashboardUsageQuery,
  useOpenDistributedModeMutation,
  useGetEnginesQuery,
  useLazyGetEnginesQuery,
  useRemoveEngineMutation,
  useLazyGetDashboardQuery
} = dashboardApi;
