import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";
export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  subscribers = [];

  jwtAxios = axios.create();

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    this.jwtAxios.defaults.baseURL = this.jwtConfig.baseURL;
    this.jwtAxios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
  }
  // ** For Refreshing Token START

  setAlreadyFetchingAccessToken(value) {
    localStorage.setItem(this.jwtConfig.setAlreadyFetchingAccessToken, value);
  }

  getAlreadyFetchingAccessToken() {
    return (
      localStorage.getItem(this.jwtConfig.setAlreadyFetchingAccessToken) ===
      "true"
    );
  }
  // ** For Refreshing Token FINISH

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.storageTokenKeyName));
  }

  getRefreshToken() {
    return JSON.parse(
      localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    );
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.jwtAxios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  socialGoogleLogin(...args) {
    return this.jwtAxios.post(
      this.jwtConfig.socialGoogleLoginEndpoint,
      ...args
    );
  }

  logout(...args) {
    return this.jwtAxios.post(this.jwtConfig.logoutEndpoint, ...args);
  }

  register(...args) {
    return this.jwtAxios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.jwtAxios.post(this.jwtConfig.refreshEndpoint, {
      refresh: this.getRefreshToken(),
    });
  }
}
