import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import useJwt from "@src/auth/jwt/useJwt";
import sysConfig from "@src/configs/sysConfig";

const baseQueryConfig = fetchBaseQuery({
  // Fill in your own server starting URL here
  baseUrl: sysConfig.backendURL,
  prepareHeaders: (headers, { getState }) => {
    // https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery#common-usage-patterns
    // TODO : get token not in useJWT but from redux state when authTokens slice implemented
    if (sysConfig.isSelfHosted) {
      return headers;
    }
    const token = useJwt.getToken();

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export default baseQueryConfig;
