// ** Initial State
const initialState = {
  tempVars: [],
  originalVars: [],
  originalData: [],
  nameOfFile: "",
  disableUploadButton: true,
};

const loadTestUploadFileVariables = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TEMP_VARS":
      return {
        ...state,
        tempVars: [...action.value],
      };
    case "SET_ORIGINAL_VARS":
      return {
        ...state,
        originalVars: action.value,
      };
    case "SET_ORIGINAL_DATA":
      return {
        ...state,
        originalData: action.value,
      };

    case "SET_NAME_OF_FILE":
      return {
        ...state,
        nameOfFile: action.value,
      };
    default:
      return state;
  }
};

export default loadTestUploadFileVariables;
