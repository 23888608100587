// ** React Imports
import { lazy, Suspense } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/components/prism-jsx.min";
import "prismjs/themes/prism-tomorrow.css";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
// ** Google login
import { GoogleOAuthProvider } from "@react-oauth/google";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
// ** Sentry integration
import sysConfig from "@configs/sysConfig";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

if (sysConfig.isSentryActive) {
  Sentry.init({
    dsn: sysConfig.sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.05,
    environment: process.env.NODE_ENV,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <LazyApp />
          <ToastContainer newestOnTop />
        </ThemeContext>
      </Suspense>
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
