// ** RootMiddleware, rootReducer import
import rootMiddleware from "../middlewares/rootMiddleware";
import rootReducer from "../reducers/rootReducer";

// ** redux toolkit
import { configureStore } from "@reduxjs/toolkit";
// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: rootReducer,
  // Turn off devtools in prod, or pass options in dev
  devTools:
    process.env?.REACT_APP_NODE_ENV === "production" ||
    process.env?.REACT_APP_NODE_ENV === "onprem"
      ? false
      : true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rootMiddleware),
});

export { store };
