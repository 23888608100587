// ** Initial State
const initialState = {
  steps: {},
};

const assertionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HANDLE_DELETE_STEP": {
      const tempSteps = {};
      Object.keys(state.steps).forEach((i) => {
        if (i < action.value.step) {
          tempSteps[i] = state.steps[i];
        } else if (i > action.value.step) {
          tempSteps[i - 1] = state.steps[i];
        }
      });
      return {
        ...state,
        steps: tempSteps,
      };
    }
    case "HANDLE_REMOVE_ASSERTION":
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.value.step]: {
            ...state.steps[action.value.step],
            [action.value.assertionNumber]: undefined,
          },
        },
      };
    case "HANDLE_CREATE_ASSERTION":
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.value.step]: {
            ...state.steps[action.value.step],
            [action.value.assertionNumber]: { data: action.value.data },
          },
        },
      };
    case "HANDLE_UPDATE_ASSERTION":
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.value.step]: {
            ...state.steps[action.value.step],
            [action.value.assertionNumber]: { data: { ...action.value.data } },
          },
        },
      };
    case "HANDLE_INSERT_ASSERTION":
      return {
        ...state,
        steps: action.value,
      };
    case "HANDLE_CLEAN_ASSERTION":
      return {
        ...state,
        steps: {},
      };
    default:
      return state;
  }
};

export default assertionsReducer;
