import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "@src/redux/storeConfig/baseQueryWithReauth";

export const pricingApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Pricing"],
  reducerPath: "pricingApi",
  keepUnusedDataFor: 1,
  refetchOnFocus: true,
  refetchOnMountOrArgChange: 10,
  endpoints: (build) => ({
    // A query endpoint with no arguments
    getSubscriptionPlans: build.query({
      query: () => "/payment/plan/",
      tag: "subscriptionPlans",
    }),
  }),
});

export const { useGetSubscriptionPlansQuery } = pricingApi;
