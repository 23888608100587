// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import analyzeFailures from "@src/redux/reducers/loadTestResult/analyzeFailures/index";
import loadTestUploadFileVariables from "@src/redux/reducers/loadTestUploadFileVariables";
import authTokensReducer from "@src/redux/storeConfig/authTokensSlice";
import tourReducer from "@src/views/about/slice/index";
import accountReducer from "@src/views/account-settings/slice/index";
import dashboardReducer from "@src/views/dashboard/slice/index";
import pricingReducer from "@src/views/pricing/slice/index";
import assertionsReducer from "@store/reducers/assertions/index";
import loadTestUploadFile from "@store/reducers/loadTestUploadFile/index";

import environment from "@store/reducers/testSuite/index";
import auth from "./auth";
import layout from "./layout";
import navbar from "./navbar";
// Import the API object
import { dashboardApi } from "@src/views/dashboard/api/dashboardApiSlice";
import { pricingApi } from "@src/views/pricing/api/pricingApiSlice";
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  environment,
  loadTestUploadFile,
  tourData: tourReducer,
  loadTestUploadFileVariables,
  analyzeFailures,
  authTokens: authTokensReducer,
  dashboardData: dashboardReducer,
  pricingData: pricingReducer,
  accountData: accountReducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  assertionsReducer,
  [pricingApi.reducerPath]: pricingApi.reducer,
});

export default rootReducer;
