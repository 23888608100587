// ** Initial State
const initialState = {
  delimiter: ",",
  vars: null,
  allow_quota: false,
  order: "random",
  skip_first_line: true,
  skip_empty_line: true,
};

const LoadTestUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_VARS":
      return {
        ...state,
        vars: [...action.value],
      };
    case "SET_DELIMETER":
      return {
        ...state,
        delimiter: action.value,
      };
    case "SET_ALLOW_QUOTA":
      return {
        ...state,
        allow_quota: action.value,
      };
    case "SET_ORDER":
      return {
        ...state,
        order: action.value,
      };
    case "SET_SKIP_FIRST_LINE":
      return {
        ...state,
        skip_first_line: action.value,
      };
    case "SET_SKIP_EMPTY_LINE":
      return {
        ...state,
        skip_empty_line: action.value,
      };

    default:
      return state;
  }
};

export default LoadTestUploadReducer;
